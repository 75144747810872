<div class="max-detail" [class.visible]="account">

  <div class="max-detail-inner">
    <!-- <header>
      <div class="close" (click)="close()"></div>
      <div *ngIf="account && account.showBackButton" class="back" (click)="back()"></div>
      <h2 *ngIf="!account">Inventory Item</h2>
      <h2 *ngIf="account" class="upper">EDIT ITEM</h2>
    </header> -->
    <!-- <section> -->
      <div *ngIf="!account" class="loading">Loading . . .</div>
      <div *ngIf="account" class="window">


        <section>
          <div class="section-header">Retail Product Editor</div>
          <div class="element">
            <div class="label">Name</div>
            <input type="text" [(ngModel)]="account.name" placeholder="Name">
          </div>
          <div class="element">
            <div class="img-mgmt">
              <div *ngIf="account.imgPreview" class="img-preview">
                <img [src]="account.imgPreview" />
              </div>
              <div *ngIf="image && image.uploaderUrl" class="img-upload">
                <iframe id="rffile" title="RF File Upload" [src]="image.uploaderUrl" frameborder="0" height="80px" width="100%"></iframe>
              </div>
            </div>
          </div>
          <div class="element">
            <div class="label">Price</div>
            <input type="text" [(ngModel)]="account.price" placeholder="$0.00">
          </div>
          <div class="element">
            <div class="label">Taxable</div>
            <app-toggle (valueChanged)="toggleBoolean('taxable')" [checked]="account.taxable"></app-toggle>
          </div>
          <div class="element">
            <div class="label">Cost</div>
            <input type="text" [(ngModel)]="account.cost" (ngModelChange)="updateAccountCosts()" placeholder="$0.00">
          </div>
          <div class="element">
            <div class="label">SKU / Barcode</div>
            <input type="text" [(ngModel)]="account.sku" />
          </div>
          <div class="element">
            <div class="label">Description</div>
            <textarea class="note-input" [(ngModel)]="account.description" placeholder=""></textarea>
          </div>

          <div class="button red delete-button" (click)="deleteValidate()">Delete Item</div>
          <div *ngIf="account.deleteError">
            <div class="error">{{account.deleteError.msg}}</div>
            <div *ngIf="account.deleteError.dependentAccounts">
              <div *ngFor="let dependentAccount of account.deleteError.dependentAccounts" class="dependent-account" (click)="viewDependentAccount(dependentAccount._id)">{{dependentAccount.name}}</div>
            </div>
          </div>

        </section>


        <div class="controls">
          <div class="controls-inner">
            <div class="button red" (click)="close()">Cancel</div>
            <div class="button green" (click)="save()">Save</div>
          </div>
        </div>

      </div>
    <!-- </section> -->
     </div>
</div>
