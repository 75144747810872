import { Pipe, PipeTransform, SecurityContext, VERSION } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import * as moment from 'moment';

@Pipe({
  name: 'rf'
})
export class RfPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, type: string): any {
    switch (type) {

      case 'safeHtml':
        return this.sanitizer.bypassSecurityTrustHtml(value);
        break;

      case 'decodeEntities':
        if (value && typeof value === 'string') {
          // strip script/html tags
          let element = document.createElement('div');
          value = value.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
          value = value.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
          element.innerHTML = value;
          value = element.textContent;
          element.textContent = '';
        }

        return value;
        break;

      case 'htmlString':
        if (value && typeof value === 'string') {
          const tempElement = document.createElement("div");
          tempElement.innerHTML = value.replace('&#38;', '&');
          return tempElement.innerText;
        } else {
          return '';
        }
        break;

      case 'key':
        return value.replace(/ /g, "-").replace(/./g, "-");
        break;

      case 'boolean':
        if (value == true) {
            return 'Yes';
        } else {
            return 'No';
        }
        break;

      case 'currency':
        if (value == null) {
          return '-';
        } else {
          let decimalValue = (value/100).toFixed(2);
          return '$'+decimalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        break;

      case 'currencyDetail':
        if (value == null) {
          return '-';
        } else {
          // return '$'+decimalValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          if (isNaN(value)) {
            return '-';
          } else {
            let decimalValue = (value/100).toFixed(4);
            return '$'+decimalValue.toLocaleString();
          }
        }
        break;

      case 'toDollars':
        if (!value) {
          value = 0;
        }
        return (value/100).toFixed(2);
        break;

      case 'toPennies':
        return value*100;
        break;

      case 'currencyInverse':
        if (value == null) {
          return '-';
        } else {
          let decimalValue = (value/-100).toFixed(2);
          return '$'+decimalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        break;

      case 'points':
        if (value == null) {
          return '-';
        } else {
          let decimalValue = (value/100).toFixed(0);
          return decimalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        break;

      case 'percent':
        return (value*100).toFixed(4);
        break;

      case 'percentage':
        if (value == null) {
          return '-';
        } else {
          return parseFloat((value*100).toFixed(2));
        }
        break;

      case 'cardNumber':
        if (value == null) {
          return '';
        } else {
          return 'xxxx'+value.replace(/\D/g,'');
        }

      case 'dateTime':
        if (value == null) {
          return '';
        } else {
          return new Date(value).toLocaleString();
        }
        break;

      case 'dateTimeYMD':
        if (value == null) {
          return '';
        } else {
          return moment(value).format('YYYY-MM-DD hh:mm:ss');
        }
        break;

      case 'dateTimeDay':
        if (value == null) {
          return '';
        } else {
          return moment(value).format('ddd M/D/YY h:mm:ss A');
        }
      case 'date':
        if (value == null) {
          return '';
        } else {
          return moment(value).format('MM/DD/Y');
        }
        break;

      case 'dateTimeShort':
        if (value == null) {
          return '';
        } else {
          return moment(value).format('M/D h:mm A');
        }
        break;

      case 'shortTime':
        if (value == null) {
          return '';
        } else {
          return moment(value).format('h:mm A');
        }
        break;

      case 'nl2br':
        if (typeof value !== 'string') {
          return value;
        }
        let result: any;
        const textParsed = value.replace(/(?:\r\n|\r|\n)/g, '<br />');

        if (!VERSION || VERSION.major === '2') {
          result = this.sanitizer.bypassSecurityTrustHtml(textParsed);
        } else if (false) { //(sanitizeBeforehand) {
          result = this.sanitizer.sanitize(SecurityContext.HTML, textParsed);
        } else {
          result = textParsed;
        }

        return result;
        break;

      case 'number':
        if (value) {
            return Number(value).toLocaleString();
        } else {
            if (value === null) {
              return '-';
            } else {
              return 0;
            }
        }
        break;

      case 'numberDetail':
        if (value) {
            value = value.toFixed(4);
            return value.toLocaleString();
        } else {
            return 0;
        }
        break;

      case 'numberInverse':
          if (value) {
              return (value*-1).toLocaleString();
          } else {
              return 0;
          }
          break;

      case 'phone':
        if (value) {
            value = String(value).replace(/\D/g,'');
            if (value.length == 11) {
              value = value.substring(0, 1)+' ('+value.substring(1, 4)+') '+value.substring(4, 7)+'-'+value.substring(7, 11);
            } else {
              return value;
            }
        }
        return value;
        break;

      case 'string':
        if (value && typeof value === 'string') {
          const tempElement = document.createElement("div");
          tempElement.innerHTML = value;
          return tempElement.innerText;
        }

        return value;
        break;

      case 'monthEndStatus':
        switch (value) {
          case 'available':
            return 'Saved';
            break;

          case 'submitted':
            return 'Submitted';
            break;

          case 'rejected':
            return 'Rejected';
            break;

          case 'accepted':
            return 'Accepted';
            break;
        }
        break;

      case 'id':
      case 'barcode':
      case 'email':
      case 'string':
      default:
        return value;
    }
  }

  parse( value: any, type: string): any {
    switch (type) {
      case 'currency':
        if ( !value?.length ) {
          return value;
        }
        value = value.substr( 1, value.length - 1);  // strip $
        value = String(parseFloat(value) * 100);  // Convert back to number
        return value;
    }
  }
}
