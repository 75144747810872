import { Component, OnInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-detail-inventory-basic',
  templateUrl: './detail-inventory-basic.component.html',
  styleUrls: ['./detail-inventory-basic.component.scss']
})
export class DetailInventoryBasicComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  account;
  accounts;
  image;

  @HostListener('window:message', ['$event']) onMessage(e) {
    console.log('e.data: ', e.data);
  
    if (e.data && e.data.type == 'fileApiResponse') {
      console.log('UPLOADER RESPONSE PASSES!');
      this.image.url = e.data.url;
      this.image.name = e.data.name;
      this.imageUpdated();
    }
  }

  constructor(
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private reportService: ReportService,
    public sanitizer: DomSanitizer,
    private authorizationService: AuthorizationService,
  ) { }

  ngOnInit(): void {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadAccount();
      }
    });
  }

  loadAccount = async () => {
    console.log('loadInventoryDetail: ');
    this.account = null;

    const account: any = await this.ioService.post('/inventory/getInventoryAccount', {
      accountId: this.documentId
    });

    for (const actId of Object.keys(account.accounts)) {
      if (!account.accounts[actId].calculatedCost && !account.accounts[actId].cost) {
        account.accounts[actId].cost = 0;
      }
    }

    this.accounts = account.accounts;

    // Define Required fields
    if (!account.taxId) {
      account.taxId = [];
    }

    if (!account.locationId) {
      account.locationId = [];
    }

    if (!Array.isArray(account.barcode)) {
      account.barcode = [account.barcode];
    }

    const barcodeObjects = [];
    for (const barcode of account.barcode) {
      barcodeObjects.push({
        value: barcode
      });
    }
    account.barcode = barcodeObjects;

    account.directCostAllowed = true;

    if (!account.price) {
      account.price = 0;
    }

    if (!account.cost) {
      account.cost = 0;
    }

    // Temporary Conversions
    account.price = (account.price / 100).toFixed(2);
    account.cost = (account.cost / 100).toFixed(4);

    if (account.imgUrl) {
      account.imgPreview = "https://redfirefile.com/render?response=file&width=320&height=216&src="+account.imgUrl;
    }

    if (account.taxId && account.taxId.length > 0) {
      account.taxable = true;
    }

    this.account = account;

    if (this.authorizationService.checkPermission('uploadImages', 'any')) {
      this.initializeFileUploader();
    }

  }

  imageUpdated = () => {
    this.image.preview = true;

    this.account.imgUrl = this.image.url;
    this.account.imgPreview = "https://redfirefile.com/render?width=320&height=216&src="+this.account.imgUrl;
  }

  initializeFileUploader = async () => {
    this.image = {};
    
    const uploader: any = await this.ioService.post('/file/requestUploader', {
      render: true,
      width: 300,
      // height: 1024,
    });
    let sanitizedIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(uploader.uploader.iFrameSrc);
    this.image.uploaderUrl = sanitizedIframeSrc;

  }

  save = async () => {
    this.account.errors = [];

    // Basic Validation

    if (!this.account.name) {
      this.account.errors.push('Name is required.');
    }

    if (this.account.errors.length > 0) {
      return false;
    }



    let account = {
      _id: this.account._id,
      name: this.account.name,
      price: this.account.price * 100,
      imgUrl: this.account.imgUrl,
      cost: this.account.cost * 100,
      description: this.account.description,
      sku: this.account.sku,
      barcode: [this.account.sku],
      taxId: this.account.taxId,
    };

    try {
      if (this.account.taxable) {
        // Get all taxes
        let taxes: any = await this.ioService.post('/account/getAccounts', {
          type: 'tax',
          locationId: this.account.locationId
        });

        account.taxId = taxes.map(tax => tax._id);
      } else {
        account.taxId = [];
      }
    } catch (e) {
      console.log('tax setting error: ', e);
    }

    await this.ioService.post('/account/updateAccount', {
      account
    });

    this.reportService.loadReport(null);
    this.close();
  }

  deleteValidate = async () => {
    if (confirm('Are you sure you want to delete this item?')) {
      this.deleteAccount();
    }
  }

  deleteAccount = async () => {
    try {
      const deleteResponse = await this.ioService.post('/inventory/deleteInventoryAccount', {
        accountId: this.account._id
      });

      setTimeout(() => {
        this.reportService.loadReport(null);
        this.close();
      }, 500);
    } catch (e) {
      // console.log('delete error: ', e.error);
      this.account.deleteError = e.error;
    }
  }

  toggleBoolean(toggleTarget) {
    this.account[toggleTarget] = !this.account[toggleTarget];
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationR.back();
  }

}
