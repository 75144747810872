<div class="detail" [class.visible]="account">
    <header>
      <div class="close" (click)="close()"></div>
      <div *ngIf="account && account.showBackButton" class="back" (click)="back()"></div>
      <h2 *ngIf="!account">Return Type</h2>
      <h2 *ngIf="account" class="upper">Return Type</h2>
    </header>
    <section>
      <div *ngIf="!account" class="loading">Loading . . .</div>
      <div *ngIf="account" class="account">
        <div class="column-general">
          <div class="field">
            <label>Name</label>
            <input type="text" [(ngModel)]="account.name" />
          </div>

          <div class="line-field">
            <div class="label-large">Price $</div>
            <input type="number" [(ngModel)]="account.price" />
          </div>

          <div class="line-field">
            <div class="label-large">Price is Variable</div>
            <app-toggle (valueChanged)="toggleBoolean('priceIsVariable')" [checked]="account.priceIsVariable"></app-toggle>
          </div>

          <div class="field">
            <label>Image</label>
            <div *ngIf="account.imgPreview" class="img-preview">
              <img [src]="account.imgPreview" />
            </div>
            <input type="text" [(ngModel)]="account.imgUrl" />
  
            <div *ngIf="image && image.uploaderUrl">
              <iframe id="rffile" title="RF File Upload" [src]="image.uploaderUrl" frameborder="0" height="80px" width="100%"></iframe>
            </div>
  
          </div>
          <div class="field">
            <label>Description</label>
            <textarea class="note-input" [(ngModel)]="account.description"></textarea>
          </div>
  
          <div *ngIf="account.ise" class="line-field">
            <div class="label-large">Sort</div>
            <input type="number" [(ngModel)]="account.sort" />
          </div>
  
          <!-- <ng-container *ngIf="account.customFields && account.customFields.length > 0">
            <div *ngFor="let customField of account.customFields" class="field">
              <label>{{customField.name}}</label>
              <input *ngIf="customField.type == 'number'" type="number" [(ngModel)]="account[customField.key]" />
              <input *ngIf="customField.type == 'text'" type="text" [(ngModel)]="account[customField.key]" />
            </div>
          </ng-container> -->

          <div class="field">
            <label>Barcode</label>
            <div *ngFor="let barcode of account.barcode; let i = index" class="line-field">
              <div class="remove" (click)="removeBarcode(i)">X</div>
              <input type="text" [(ngModel)]="barcode.value" />
            </div>
            <div style="text-align: right; padding-bottom: 8px;">
              <div style="display: inline-block; font-size: 20px; font-weight: 700; padding: 0 16px;" (click)="addBarcode()">+</div>
            </div>
          </div>

          <div *ngFor="let optionGroup of optionFields; let i = index" class="field"
                [class.hidden]="optionGroup.key === 'taxId' && !showTaxControl">
            <app-dropdown *ngIf="!(optionGroup.key === 'taxId' && !showTaxControl)"
                [optionGroup]="optionFields[i]"
                (searchChanged)="search(optionGroup, $event)">
            </app-dropdown>
          </div>

          <div class="buttons">
            <div class="button red" (click)="deleteAccount()">Delete Item</div>
            <div *ngIf="account.deleteError">
              <div class="error">{{account.deleteError.msg}}</div>
            </div>
            <div class="button green" (click)="save()">Save</div>
            <div *ngFor="let error of account.errors" class="error">{{error}}</div>
                </div>
            </div>
        </div>
    </section>
</div>
